<template>
    <HomeContentLayout>
        <Layout>
            <Sider  hide-trigger :collapsed-width="64"  class="siderCls" style="background:#fff,width: 260px;min-width: 260px;max-width:260px;" >
                <el-input placeholder="请输入关键字进行过滤" v-model="filterText" size="medium"></el-input> 
                <el-tree :data="treeData" 
                         :props="defaultProps" 
                         @node-click="handleNodeClick" 
                         node-key="id"      
                         :default-expanded-keys="['0']"     
                         v-loading="loadingtree"
                         element-loading-text="加载中"
                         element-loading-spinner="el-icon-loading"
                         element-loading-background="rgba(255, 251, 255, 0.8)"               
                         :style="{background:'#fff',overflow:'auto',height: scrollerHeight}"
                         highlight-current
                         :filter-node-method="filterNode"
                         ref="eltree"
                         :expand-on-click-node="false">
                    <template v-slot="{node,data}">                                     
                        <span v-if="data.id ==0 "  class="el-icon-notebook-1 nodeRoot" >
                          <span class="nodeLabel">{{ node.label}} </span>
                        </span>                  
                        <span v-else class="el-icon-folder" > 
                            <span class="nodeLabel">{{ node.label}} </span>
                        </span>
                    </template>
                </el-tree>
            </Sider>
            <Layout>
                <Content>
                    <div style="vertical-align:center; padding-left:1px;margin-top:6px;display:flex;height:32px;background:white">  
                        <el-input :placeholder="input_hold" v-model="textparam" size="small" @keyup.enter.native="qryTabData" style="width:400px; vertical-align:center">
                            <el-select v-model="selparam" slot="prepend" placeholder="请选择列名" clearable @change="elchange" style="width:140px;">
                                <span v-for="item  in tabCol" :key='item.key'>
                                    <el-option :label="item.title" :value="item.key"></el-option>
                                </span>
                            </el-select>
                        </el-input> 
 
                    </div>      
                    <div>
                    <el-table  :data="baseData" 
                            border                            
                            disabled-hover
                            style="overflow:auto;margin-top:1px;"
                            v-loading="loading"
                            element-loading-text="加载中"
                            element-loading-spinner="el-icon-loading"
                            element-loading-background="rgba(255, 251, 255, 0.8)"  
                            size="small"
                            ref="tabref"
                            :height="300" 
                            highlight-current-row
                            @row-click="rowClick"  >
           
                            <el-table-column  v-for="(item,index) in tableCol.filter(item=>item.thide=='N')" :key="index"
                                :prop="item.key"
                                :label="item.title"
                                show-overflow-tooltip
                                :align="item.align"                                  
                                :sortable="item.key!=='RN'&&item.key!=='STFG'"
                                :min-width="item.width">
                                <template  slot-scope="scope" >
                                    <el-checkbox disabled v-model="scope.row[item.key]" v-if="item.key=='STFG'" true-label="Y" false-label="N"></el-checkbox>
                                    <span v-else v-text="scope.row[item.key]"></span>
                                </template>
                            </el-table-column>
 
 
                        <el-table-column
                            fixed="right"
                            label="操作"
                            align="center"
                            width="140">
                            <template slot-scope="scope">
                                <el-button size="small" :disabled="scope.row.LVL<'3'"  type="text" icon="el-icon-edit" @click="editRec(scope.row,scope.$index)">编辑</el-button>
                                <el-button v-if="scope.row.STFG=='N'" size="small"  :disabled="scope.row.LVL<'3'" type="text" icon="el-icon-check" @click="cfmRec(scope.row,'Y')">审核</el-button>
                                <el-button v-if="scope.row.STFG=='Y'" size="small"  :disabled="scope.row.LVL<'3'" type="text" icon="el-icon-close" @click="cfmRec(scope.row,'N')">反审核</el-button>  
                            </template>
                        </el-table-column>   
                    </el-table>
                    </div>
                    <div :style="{'overflow-y':'auto',height:tabHeight,'margin-top':'5px'}" >
                        <div v-for="(gpitem,index) in tabFrmCol" :key="index" style="margin-bottom:15px;">
                            <div class="one-toolbar">
                                <span  class="spanRow">{{gpitem.gpnm}} </span>  
                            </div>
                            <Form :model="propRow" :label-width="110" ref="matWin"  label-position="right" inline   >  
                            <span v-for="item  in gpitem.gplist" :key="item.FLDNO" >
                                <FormItem  :label="item.FLDNM"  class="item-width" v-if="item.FHIDE==='N'" :prop="item.FLDNO"  >                  
                                    <el-date-picker disabled type="date"  v-if="item.EDCSS==='date'" v-model="propRow[item.FLDNO]" clearable value-format="yyyy-MM-dd" style="width:100%" ></el-date-picker>   
                                    <el-input-number disabled style="width:90%"   v-else-if="item.EDCSS==='number'"  v-model="propRow[item.FLDNO]"></el-input-number>
                                    <el-input readonly type="text" v-else  v-model="propRow[item.FLDNO]"></el-input>
                                </FormItem>
                            </span>
                            </Form>
                        </div>
                    </div>
                    <Modal v-model="addModal" footer-hide :mask-closable="false"  scrollable  width="1000" :styles="{left:'5px',top:'2px'}">
                        <div>
                            <Button v-if="saveFlag===true" type="dashed" plain   @click="modal_ok"  icon="md-checkbox" style="margin-bottom:5px;margin-right:20px;">保存</Button>                   
                            <Button type="dashed" plain   @click="addModal=false"  icon="md-close" style="margin-bottom:5px;margin-right:20px;">取消</Button>
                            <el-image fit="scale-down"  style="width:135px;  margin-left:20px;height:35px;vertical-align:middle" :src="imgSrc" > </el-image>
                        </div>
                        <el-card v-for="(gpitem,index) in tabFrmCol" :key="index" style="margin-bottom:15px;">
                            <p slot="header" style="color:#5cadff;height:14px;line-height:14px ">
                                <span  style="font-weight:600;font-size:14px;color:#5cadff">{{gpitem.gpnm}} </span>        
                            </p>
                            <Form :model="prcRow" :label-width="110" ref="matWin"  label-position="right" inline   >  
                            <span v-for="item  in gpitem.gplist" :key="item.FLDNO" >
                                <FormItem  :label="item.FLDNM"  class="item-width" v-if="item.FHIDE==='N'" :prop="item.FLDNO"  >                  
                                    <el-date-picker type="date"  v-if="item.EDCSS==='date'" v-model="prcRow[item.FLDNO]" clearable value-format="yyyy-MM-dd" style="width:100%" ></el-date-picker>   
                                    <el-input-number :disabled="item.DISABLED=='Y'?true:false" controls-position="right" :precision="item.DOTNUM" v-else-if="item.EDCSS==='number'" v-model="prcRow[item.FLDNO]" style="width:100%" ></el-input-number>   
                                    <el-input :disabled="item.DISABLED=='Y'?true:false" type="text" v-else   v-model="prcRow[item.FLDNO]"></el-input>
                                </FormItem>
                            </span>
                            </Form>
                        </el-card>                    
                        <div slot="header" style="height:10px;line-height:10px">
                            <span style="font-weight:600;font-size:14px;color:#ccc">编辑记录 </span>
                        </div>
                    </Modal>
 
                </Content>
            </Layout>
        </Layout>
    </HomeContentLayout>
</template>

<script>
    import { getTabColOrData } from '../../api/user';
    export default {
        name: "prdprc_set",
        data(){
            return {
                treeData:[] ,//  树数据
                filterText: '',
                prcRow:{},
                ruleValidate:{},
                saveFlag:true,
                input_hold:'请输入内容...',
                defaultProps: {  //el-tree 默认字段属性
                    children: 'children',
                    id:'id',
                    icon:'icon',
                    label: 'label'
                },
                imgSrc:'',
 
                treeid :'0',
                type: 'create',
 
                formCol:{}, //表格单行记录 model
                addModal:false, //modal 
                loading:false,// table加载bool
                textparam:'', // input 绑定值
                selparam:'',// select 绑定值
                tabCol:[] ,//表列集合
                tableCol:[] ,//表列格式化集合
                baseData:[] ,//表数据
                allData:[] , //保存取到所有数据
                nodeLabel:'产品类别',
                subno:'', //编码
 
                loadingtree:false,
                propRow:{},
                // 初始化信息总条数
                dataCount:0,
                rowIndex:'-1',
                v_url:this.$store.state.queryUrl , //api请求路径
                v_urlcol:this.$store.state.baseUrl+'sysprivs/getFormColumn',
         
                v_username:this.$cookies.get('v_username'), //api 请求用户
                v_password:this.$cookies.get('v_password') ,//api 请求密码
                v_requrl:this.$store.state.baseUrl+'sysprivs/getTableRecMany', //动态请求url接口
                v_formurl:this.$store.state.baseUrl+'user/getFormGroup', //多记录查询 like模式  
                tabFrmCol:[], // 分组列
 
            }
        },
        created(){
            //初始化
            this.getData();
            this.getTabCol();
            this.getFormCol();
            this.getTabData('parentno','0','','','','');
        },
        mounted(){
            //供应商资料分组 
            getTabColOrData(this.v_formurl,this.v_username,this.v_password,'v_sys_form_fld','idseq','60','gpnm,gpsort','','fhide','N',' order by gpsort').then((res) => {
                this.tabFrmCol = res.data.result
            });  
        },
        computed:{
            scrollerHeight: function() {
                return (window.innerHeight - 140) + 'px';
            }, 
            tabHeight(){
                return (window.innerHeight - 440) + 'px';
            },           
        },
        watch:{
            filterText(val) {
                this.$refs.eltree.filter(val);
            }
        },
        destroyed(){
        },
        methods:{
            //节点过滤
            filterNode(value, data) {
                if (!value) return true;
                return data.label.indexOf(value) !== -1;
            },
            //节点点击
            handleNodeClick(data){
                this.nodeLabel=data.label;            
                this.treeid =data.id
                this.getTabData('parentno',data.id,'','','','');
                this.propRow={}
            },
            //获取表行数据
            rowClick (row) {
                this.prcRow = JSON.parse(JSON.stringify(row))  //深拷贝 //获取行数据
                this.propRow = JSON.parse(JSON.stringify(row))
                this.subno =row.SUBNO 
            },
 
            //确定
            modal_ok(){
                //发起保存请求  
                let obj =Object.assign(this.prcRow,{SUBNO:this.subno})  
                this.$axios({
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/formOneSave?username='+this.v_username+'&password='+this.v_password,
                    data: {p_json:JSON.stringify(obj), p_table:'PRD_CATEGORY',p_frmid:''},
                }).then(res=>{      
                    if(res.data.result ==='OK')  {          
                        this.addModal =false    
                        this.propRow =JSON.parse(JSON.stringify(obj)) 
                        this.baseData.splice(this.rowIndex,1, this.prcRow )
                        //console.log(JSON.stringify(this.rowIndex+':'+this.baseData[this.rowIndex])) 
                    }else{
                        this.$Message.error(res.data.result)
                    }        
                }) 
            } ,
            //修改
            editRec(row,index){
                this.addModal =true
                this.rowIndex =index
                if (row['STFGNM']==='审核')  {  
                    this.saveFlag=false
                    this.imgSrc='/assets/basedict/confirm.png'
                }else{
                    this.imgSrc='/assets/basedict/no_confirm.png'
                    this.saveFlag=true
                }
            },
            //状态变更
            cfmRec(row,val){
                if (row.STFGNM==='审核' && val==='Y'){
                    this.$Message.error('此记录已是审核状态' ) 
                }else if (row.STFGNM==='未审核' && val==='N'){
                    this.$Message.error('此记录已是未审核状态' ) 
                }else {
                    let v_comm=''
                    if (val==='Y'){
                        v_comm ='审核'
                    }else{
                        v_comm ='未审核'
                    }
                    this.$Modal.confirm({
                        title: '提示',
                        content: '<p>你确定要'+v_comm+'此行记录吗</p>',
                        onOk: () => {
                            var vm=this;
                            this.$axios({
                                method: 'post',
                                url:vm.$store.state.baseUrl+'user/updateRec?username='+this.v_username+'&password='+this.v_password,
                                data: {p_key:'idseq',p_value:row.IDSEQ,p_table:'PRD_CATEGORY',p_cond:' set stfg=\''+val+'\' '},
                            }).then(res=>{      
                                if(res.data.code =='200')  {           
                                    Object.assign(row,{STFGNM:v_comm,STFG:val})
                                }else{
                                    this.$Message.error(res.data.result)
                                }        
                            }) 
                        }
                    });
                }
            },
    
            // el-select 改变时 影响el-input 的 placeholder
            elchange(val){  // 这个val 就是 el-select 的 value
                let obj ={}
                obj =this.tabCol.find(item=>{
                    return item.key===val
                })
                this.input_hold='请输入'+obj.title+' 的值'
            },
            //查询事件
            qryTabData(){
                var vm=this
                this.loading =true
                getTabColOrData(this.v_requrl,this.v_username,this.v_password,'V_PRDTYPE_MGT','parentno',this.treeid,'','','','',' and upper('+this.selparam+') like \'%'+this.textparam+'%\'').then((res) => {
                    vm.baseData = res.data
                    this.loading =false
                });  
            },
            // 获取 树形控件数据
            getData() {  
                var vm=this;
                vm.loadingtree=true
                this.$axios({
                    method: 'post',
                    url:vm.$store.state.baseUrl+'sysprivs/getElTreeData?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                    data: {p_table:vm.LANG==='English'?'V_PRD_CATEGORY_EN':'V_PRD_CATEGORY'}
                }).then(function(res){                  
                    vm.treeData =res.data;   
                    if(res.data.length > 0 ){
                        //'nextTick()' 下次dom更新时触发回调函数
                        vm.$nextTick().then(() =>{
                            //使用setCurrentKey方法的时候必须将其放在nextTick中,要等数据更新渲染完再调用,否则找不到对应元素
                            vm.$refs.eltree.setCurrentKey(vm.treeid)
                        })
                    }     
                    vm.loadingtree =false          
                })                              
            },
            //获取表列头字段
            getTabCol(){
                var vm=this
                getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','60','fty','HEAD','thide','N',' order by sortby,lstseq').then((res) => {
                    vm.tabCol = res.data
                    for (let k=0; k<res.data.length; k++){
                        vm.tableCol.push({ //向数组的开头 添加序列号
                            title: res.data[k].title,
                            key: res.data[k].key,
                            align: res.data[k].align,
                            width: res.data[k].width,
                            thide: res.data[k].THIDE,
                            sortable: res.data[k].sortable?true:false,
                        })
                    }
                });
            },
            //获取表单字段
            getFormCol(){
                var vm=this
                getTabColOrData(vm.v_urlcol,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','60','fty','HEAD','thide','N','').then((res) => {
                    vm.formCol = res.data
                });
            },
            //获取表数据
            getTabData(v_key,v_value,v_key2,v_value2,v_key3,v_value3){
                var vm=this
                this.loading =true
                getTabColOrData(this.v_requrl,this.v_username,this.v_password,'V_PRDTYPE_MGT',v_key,v_value,v_key2,v_value2,v_key3,v_value3,'').then((res) => {
                    vm.baseData = res.data
                    this.loading =false
                    //setTimeout(()=>{this.loading =false},2000) //测试效果
                });               
            }
        }
    }
</script>
<style lang="scss" scoped>
    ::v-deep .el-table {
        th {
            padding: 0px ;
        }
        thead{
            height: 15px !important;
            color:rgb(71, 70, 70);
            font-size:12px;
        }
        td {
            padding: 3px ;
            font-size:11px;
        }   
    }
</style>
<style lang="less"  >
    .menu__item {
        width: 100%;
        display: block;
        line-height: 20px;
        text-align: center;
        margin-top: 10px;
        text-align: center;

    }
 
    .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after{
        border-color :#3d77eb;
    }
    .el-checkbox__input.is-disabled+span.el-checkbox__label{
        color:#3d77eb
    }
</style>
